import { Routes, Route } from "react-router-dom";
import "./styles.css";
import Album from "./components/Album";
import { useState, useEffect } from "react";
import ReactTouchEvents from "react-touch-events";

import Navigation from './config/new_nav.json'

console.log(Navigation)
const ID_PAGES = [];
_id_pages(Navigation, ID_PAGES);


export default function App() {

  const [evt, setEvent] = useState()

  return (
    <ReactTouchEvents onTap={evt => setEvent(evt)}>

      <div id="top">

        {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}
        <Routes>
          <Route index element={<Home root={Navigation} />} />
          {ID_PAGES.filter(page => !page.pages).map(page =>
            <Route path={page.id} element={<MediaPage category={page.id} />} />
          )}
          {ID_PAGES.filter(page => !!page.pages).map(page =>
            <Route path={page.id} element={<Home root={page} />} />
          )}
          <Route path="*" element={<Home root={Navigation} />} />
        </Routes>
      </div>

    </ReactTouchEvents>

  );
}


function Home({ root }) {

  const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    function handleResize() {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth, window.innerHeight]);

  const bw = 2200.
  const bh = 2351.

  const w1 = window.innerWidth;
  const h1 = window.innerHeight;

  const h2 = (bh * w1) / bw;
  const w2 = (bw * h1) / bh;

  let scale = 1.;
  let offW = 0;
  let offH = 0;
  if (h2 < h1) {
    scale = w1 / bw;
    offH = (h1 - h2) / 2.
  } else {
    scale = h1 / bh;
    offW = (w1 - w2) / 2.
  }

  document.body.classList.add('home')

  const parentId = getParentId(root.id);
  const pages = root.pages

  return (
    <div className="cartouche-container">
      {pages.map((page, index) =>
        <Cartouche
          rect={convert(_makePosition(page, index), offW, offH, scale)}
          name={page.title}
          img={page.img}
          scale={scale}
          target={page.id}
        />
      )}
    </div>
  )
}

function _makePosition(page, index) {
  if (page.position) {
    return page.position
  }

  //x, y, w, h
  return [640, 500 + index * 180, 800, 140]

}

function Cartouche({ rect, target, name, img, scale }) {


  const styles = {
    left: rect.x + 'px',
    top: rect.y + 'px',
    width: rect.w + 'px',
    height: rect.h + 'px',
    lineHeight: rect.h + 'px',
    color: 'black',
    textAlign: 'center',
    margin: 'auto',
    fontWeight: 'bold',
    fontSize: (parseInt(50 * scale)) + 'px',
    backgroundColor: '#f7ead3',
    //backgroundImage: `url(https://famille.solognot.net/thumbnail%2Fimages%2F${name}.png)`
  };
  if (img) {
    styles.backgroundImage = `url(https://famille.solognot.net/thumbnail/images/${encodeURIComponent(img)})`
  } else {
    styles.border = '2px solid #a1641d'
  }


  return (
    <div className="cartouche" style={styles} onClick={() => { document.location.hash = target }}>
      {img ? '' : name}
    </div>
  )

}

function convert(position, offW, offH, scale) {
  const [x, y, w, h] = position;
  let R1 = { x, y, w, h }
  R1.x = parseInt(offW + scale * R1.x)
  R1.y = parseInt(offH + scale * R1.y)
  R1.w = parseInt(scale * R1.w)
  R1.h = parseInt(scale * R1.h)

  return R1;
}

function MediaPage({ category }) {
  document.body.classList.remove('home')

  return (
    <Album category={category} />
  );

}

function _id_pages(root, collect) {
  if(root.back) return;
  collect.push(root);
  if (root.pages) {
    root.pages.forEach(page => {
      _id_pages(page, collect);
    });
  }

}


export function getParentId(id) {
  return search(Navigation, id);
}

function search(root, id) {
  if (root.pages) {
    for (const page of root.pages) {
      if (page.id === id) {
        return root.id
      }
      const found_id = search(page, id);
      if (found_id) {
        return found_id;
      }
    }
  }
  return null;
}
