import { Link } from "react-router-dom";
import "../styles.css";
import { useEffect, useState } from "react";
import PhotoAlbum from "react-photo-album";
import ReactImageVideoviewer from '../simple_viewer/index.js'
import { useNavigate } from 'react-router-dom';
import { getParentId } from '../App.js'

import DATA from '../config/new_data.json'

export default function Album({ category }) {

  const [video, setVideo] = useState([])
  const [open, setopen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentIndex(0)
    setVideo(
      DATA[category]
      .map((item, index) => ({ src: item.poster, width: item.width, height: item.height, type: item.type, item, id: index }))
    );
  }, [category])



  const openlightbox = (index) => {
    console.log(index);
    setCurrentIndex(index);
    setopen(true);
  };
  return (
    <div className="App">

      <Link className="link-header"  to={'/' + getParentId(category)}>Retour</Link>
      <hr />

      <PhotoAlbum
        layout="rows"
        photos={video}

        onClick={({ index }) => openlightbox(index)}

        renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => (
          <a href={photo.href} style={wrapperStyle} className={photo.item.type === 'video' ? 'video-thumbnail' : ''} id={photo.id} >

            {renderDefaultPhoto({ wrapped: true })}

            <h3>{photo.item.date ? photo.item.date + ' - ' : ''} {photo.item.title}</h3>
          </a>
        )}

      />

      {open && (document.title = _formatPageTitle(video[currentIndex].item)) && (
        <ReactImageVideoviewer
          data={video.map(obj => obj.item)}
          startIndex={currentIndex}
          showResourceCount={true}
          onCloseCallback={() => setopen(false)}
          onNavigationCallback={(currentIndex) => {
            console.log(`Current index: ${currentIndex}`)
            document.title = _formatPageTitle(video[currentIndex].item);
            document.getElementById(currentIndex).scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
          }
        />
      )}

      <hr />
      <Link className="link-header"  to={'/' + getParentId(category)}>Retour</Link>


    </div>
  );
}


function _formatPageTitle(item) {
  return ( item.date ? `${item.date} - ` : '') + item.title;
}